import React from 'react';
import { func, string } from 'prop-types';
import { intlShape, injectIntl } from '../../../util/reactIntl';
import { AutoSuggestion } from '../../../components';
import { Form as FinalForm, Field } from 'react-final-form';
import schoolsData from '../../../assets/schoolData.json';
import SearchIcon from '../../TopbarContainer/Topbar/SearchIcon';

import css from './SectionHero.module.css';
import { convertToDocumentationFormat } from '../../../util/dataExtractors';
import routeConfiguration from '../../../routing/routeConfiguration';
import { createResourceLocatorString } from '../../../util/routes';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const FinalFormAutoSuggestion = ({ input, meta, schoolsData, ...rest }) => {
  const history = useHistory();

  const onSearchBySchool = value => {
    const preferredSchoolsSchema = convertToDocumentationFormat([value?.value]);
    history.push(
      createResourceLocatorString(
        'SearchPage',
        routeConfiguration(),
        {},
        { pub_preferredSchools: preferredSchoolsSchema }
      )
    );
  };

  return (
    <AutoSuggestion
      {...rest}
      data={schoolsData}
      value={input.value}
      isMulti={false}
      onSelectionChange={onSearchBySchool}
    />
  );
};

const LandingSearchFormComponent = props => {
  const { onSubmit } = props;

  const handleSubmit = values => {
    // Call the onSubmit function passed as prop with form values
    onSubmit(values);
  };

  return (
    <FinalForm onSubmit={handleSubmit}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className={css.landingHeroSearch}>
          <div className={css.searchIcon}>
            <SearchIcon />
          </div>
          <Field
            name="keywords"
            component={FinalFormAutoSuggestion}
            schoolsData={schoolsData || []}
            searchKeys={['school_name', 'suburb', 'postcode']}
          />
        </form>
      )}
    </FinalForm>
  );
};

LandingSearchFormComponent.defaultProps = { rootClassName: null, className: null };

LandingSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  onSubmit: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const LandingSearchForm = injectIntl(LandingSearchFormComponent);

export default LandingSearchForm;
