import React from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import uniform from './SectionAssets/category-image1.png';
import corePeUniform from './SectionAssets/category-image2.png';
import sportsImage from './SectionAssets/category-image3.png';
import cadetImage from './SectionAssets/category-image4.png';

import css from './Sections.module.css';

const getSteps = intl => [
  {
    img: uniform,
    title: intl.formatMessage({ id: 'BrowseCategory.academicUniform.title' }),
    description: intl.formatMessage({ id: 'BrowseCategory.academicUniform.description' }),
    search: 's?pub_listingType=school_uniforms&pub_subcategory=school_uniforms_academic_uni',
  },
  {
    img: sportsImage,
    title: intl.formatMessage({ id: 'BrowseCategory.peUniform.title' }),
    description: intl.formatMessage({ id: 'BrowseCategory.peUniform.description' }),
    search: 's?pub_listingType=school_uniforms&pub_subcategory=school_uniforms_sporting_uni',
  },
  {
    img: cadetImage,
    title: intl.formatMessage({ id: 'BrowseCategory.sportingUniforms.title' }),
    description: intl.formatMessage({ id: 'BrowseCategory.sportingUniforms.description' }),
    search: 's?pub_listingType=books',
  },
  {
    img: corePeUniform,
    title: intl.formatMessage({ id: 'BrowseCategory.textbooks.title' }),
    description: intl.formatMessage({ id: 'BrowseCategory.textbooks.description' }),
    search: 's?pub_listingType=rec_items',
  },
];

function BrowseCategory(props) {
  const history = useHistory();
  const { intl } = props;

  function Step({ step }) {
    return (
      <div className={css.sliderItem} onClick={() => history.push(step?.search)}>
        <div className={css.imageBlock}>
          <img src={step.img} alt={step.title} />
        </div>
        <h3>{step.title}</h3>
        {/* <p>{step.description}</p> */}
      </div>
    );
  }

  return (
    <div className={classNames(css.popularSessions, css.sectionPadding)}>
      <div className={css.contentWidth}>
        <div className={css.sectionHead}>
          <div className={css.leftSec}>
            <h2>{intl.formatMessage({ id: 'BrowseCategory.heading' })}</h2>
          </div>
        </div>
        <div className={css.templatesSlider}>
          {getSteps(intl).map((step, index) => (
            <Step key={index} step={step} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default BrowseCategory;
