import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './Topbar.module.css';

const SearchIcon = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.rootSearchIcon, className);

  return (
    <svg
      className={classes}
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.27377 2.07051C4.22129 2.07051 1.74678 4.54502 1.74678 7.5975C1.74678 10.65 4.22129 13.1245 7.27377 13.1245C8.80018 13.1245 10.1811 12.5065 11.1819 11.5057C12.1828 10.5048 12.8008 9.12391 12.8008 7.5975C12.8008 4.54502 10.3262 2.07051 7.27377 2.07051ZM0.518555 7.5975C0.518555 3.8667 3.54297 0.842285 7.27377 0.842285C11.0046 0.842285 14.029 3.8667 14.029 7.5975C14.029 9.24153 13.4411 10.7492 12.465 11.9202L16.3056 15.7608C16.5454 16.0006 16.5454 16.3895 16.3056 16.6293C16.0657 16.8691 15.6769 16.8691 15.4371 16.6293L11.5965 12.7887C10.4254 13.7648 8.9178 14.3527 7.27377 14.3527C3.54297 14.3527 0.518555 11.3283 0.518555 7.5975Z"
        fill="#4a4a4f"
      />
    </svg>
  );
};

const { string } = PropTypes;

SearchIcon.defaultProps = {
  className: null,
  rootClassName: null,
};

SearchIcon.propTypes = {
  className: string,
  rootClassName: string,
};

export default SearchIcon;
