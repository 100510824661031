import React from 'react';
import { Heading } from '../../../components';
import css from './Sections.module.css';

function SectionBuySellDonate(props) {
  const { intl } = props;
  const heading = intl.formatMessage({ id: 'SectionBuySellDonate.heading' });
  const description = intl.formatMessage({ id: 'SectionBuySellDonate.description' });

  return (
    <div className={css.heroContainer}>
      <div className={css.heroRight}>
        <div className={css.contentWidth}>
          <div className={css.heroContent}>
            <Heading as="h1" rootClassName={css.heading}>
              {heading}
            </Heading>
            <p>{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionBuySellDonate;
