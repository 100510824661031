import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { Page, LayoutSingleColumn } from '../../components';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import FooterContainer from '../../containers/FooterContainer/FooterContainer';
import { withViewport } from '../../util/uiHelpers';
import { useConfiguration } from '../../context/configurationContext';

import css from './LandingPage.module.css';

//Static assets
import facebookImage from '../../assets/biketribe-facebook-sharing-1200x630.jpg';
import twitterImage from '../../assets/biketribe-twitter-sharing-600x314.jpg';
import SectionHero from './SectionHero/SectionHero';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import BrowseCategory from './Sections/BrowseCategory';
import SectionBuySellDonate from './Sections/SectionBuySellDonate';
import SectionTellMore from './Sections/SectionTellMore';
import SectionReview from './Sections/SectionReview';
import SectionSustainability from './Sections/SectionSustainability';
import SectionBenefits from './Sections/SectionBenefits';

//Section pages

export const LandingPageComponent = props => {
  const { intl, scrollingDisabled, viewport } = props;

  const config = useConfiguration();

  const marketplaceName = config.marketplaceName;

  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { marketplaceName });
  const schemaDescription = intl.formatMessage({
    id: 'LandingPage.schemaDescription',
  });
  const schemaImage = `${config.marketplaceRootURL}${facebookImage}`;

  const [scroll, setScroll] = useState(false);

  const isHeaderSticky = scroll ? css.sticky : '';

  const isMobileLayout = viewport.width <= 768;

  useEffect(() => {
    let isMounted = true;
    if (typeof window !== 'undefined') {
      const handleScroll = () => {
        if (isMounted) {
          setScroll(window.scrollY > 0);
        }
      };
      window.addEventListener('scroll', handleScroll);
      return () => {
        isMounted = false; // This is the cleanup for event listener
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        {
          url: `${config.marketplaceRootURL}${twitterImage}`,
          width: 600,
          height: 314,
        },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn
        className={css.pageRoot}
        topbar={
          <TopbarContainer
            isLandingPage={true}
            isThemeBgNav={false}
            isHeaderSticky={isHeaderSticky}
          />
        }
        footer={<FooterContainer />}
      >
        <div className={css.pageContent}>
          <SectionHero intl={intl} />
          {isMobileLayout ? null : <SectionBuySellDonate intl={intl} viewport={viewport} />}
          <BrowseCategory intl={intl} viewport={viewport} />
          <SectionBenefits intl={intl} viewport={viewport} />
          <SectionReview intl={intl} viewport={viewport} />
          <SectionSustainability intl={intl} viewport={viewport} />
          <SectionTellMore intl={intl} viewport={viewport} />
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool } = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
  };
};

const LandingPage = compose(
  withRouter,
  connect(mapStateToProps, null),
  withViewport,
  injectIntl
)(LandingPageComponent);

export default LandingPage;
