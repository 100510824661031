import React from 'react';
import { Icons } from '../../../components';
import classNames from 'classnames';

import css from './Sections.module.css';

function SectionReview(props) {
  const { intl } = props;
  const quote = intl.formatMessage({ id: 'SectionReview.quote' });
  const author = intl.formatMessage({ id: 'SectionReview.author' });
  const authorTitle = intl.formatMessage({ id: 'SectionReview.authorTitle' });

  return (
    <div className={css.contentWidth}>
      <div className={classNames(css.reviewSection, css.sectionPadding)}>
        <div className={css.sectionContent}>
          <div className={css.reviewContent}>
            <figure>
              <blockquote>
                <p>
                  <span className={css.leftQuote}>
                    <Icons name="quote" />
                  </span>
                  {quote}
                  <span className={css.rightQuote}>
                    <Icons name="quote" />
                  </span>
                </p>
              </blockquote>
              <figcaption>
                <cite>{author}</cite>
                <p>{authorTitle}</p>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionReview;
