import React from 'react';
import { Heading, NamedLink } from '../../../components';
import heroImg from '../../../assets/heroimg.jpg';
import logo from '../../../assets/logo.png';
import { useRouteConfiguration } from '../../../context/routeConfigurationContext';
import { createResourceLocatorString } from '../../../util/routes';
import LandingSearchForm from './LandingSearchForm';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import css from './SectionHero.module.css';

function SectionHero(props) {
  const { intl } = props;

  const history = useHistory();
  const routeConfiguration = useRouteConfiguration();
  const description = intl.formatMessage({ id: 'LandingPage.description' });
  const descriptionExplainerLabel = intl.formatMessage({ id: 'LandingPage.description1' });

  const handleSubmit = values => {
    const searchQuery = {
      ...values,
    };

    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchQuery));
  };

  const search = (
    <LandingSearchForm
      rootClassName={css.landingSearchForm}
      onSubmit={handleSubmit}
      intl={intl}
      isHeroSearch={true}
    />
  );

  return (
    <div className={css.heroContainer}>
      <div className={css.heroLeft}>
        <img src={heroImg} alt="image" />
      </div>
      <div className={css.heroRight}>
        <div className={css.contentWidth}>
          <div className={css.heroContent}>
            <Heading as="h3" rootClassName={css.subHeading}>
              {description}
            </Heading>
            <img className={css.logo} src={logo} alt="New Owner" />
            <p>{descriptionExplainerLabel}</p>
            {search}
            <div className={css.heroBtns}>
              <NamedLink name="SearchPage" className={css.createLink}>
                {intl.formatMessage({ id: 'SectionHero.shopNow' })}
              </NamedLink>
              <NamedLink name="NewListingPage" className={css.createLink}>
                {intl.formatMessage({ id: 'SectionHero.sellNow' })}
              </NamedLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionHero;
