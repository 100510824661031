import React from 'react';
import { injectIntl } from 'react-intl';
import { Icons } from '../../../components';
import classNames from 'classnames';

import css from './Sections.module.css';

const steps = [
  {
    icon: 'kaula',
    title: 'SectionBenefits.australiaWide',
    description: 'SectionBenefits.australiaWideDescription',
  },
  {
    icon: 'money',
    title: 'SectionBenefits.payWithConfidence',
    description: 'SectionBenefits.payWithConfidenceDescription',
  },
  {
    icon: 'phoneNumber',
    title: 'SectionBenefits.quickAndEasy',
    description: 'SectionBenefits.quickAndEasyDescription',
  },
  {
    icon: 'house',
    title: 'SectionBenefits.withinYourCommunity',
    description: 'SectionBenefits.withinYourCommunityDescription',
  },
  {
    icon: 'efficiency',
    title: 'SectionBenefits.helpingTheEnvironment',
    description: 'SectionBenefits.helpingTheEnvironmentDescription',
  },
  {
    icon: 'school',
    title: 'SectionBenefits.supportYourSchool',
    description: 'SectionBenefits.supportYourSchoolDescription',
  },
];

function Step({ step, intl }) {
  return (
    <div className={css.contentBlock}>
      <Icons name={step.icon} />
      <h2>{intl.formatMessage({ id: step.title })}</h2>
      <p>{intl.formatMessage({ id: step.description })}</p>
    </div>
  );
}

function SectionBenefits(props) {
  const { intl } = props;
  return (
    <div className={css.contentWidth}>
      <div className={classNames(css.ourBenfits, css.sectionPadding)}>
        <div className={css.sectionHead}>
          <div className={css.leftSec}>
            <h2 />
          </div>
        </div>
        <div className={css.sectionContent}>
          {steps.map((step, index) => (
            <Step key={index} step={step} intl={intl} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default injectIntl(SectionBenefits);
