import React from 'react';
import { NamedLink } from '../../../components';
import classNames from 'classnames';
import css from './Sections.module.css';

function SectionTellMore(props) {
  const { intl } = props;
  const heading = intl.formatMessage({ id: 'SectionTellMore.heading' });
  const howToDonate = intl.formatMessage({ id: 'SectionTellMore.howToDonate' });
  const howToBuy = intl.formatMessage({ id: 'SectionTellMore.howToBuy' });
  const howToSell = intl.formatMessage({ id: 'SectionTellMore.howToSell' });
  const ourStory = intl.formatMessage({ id: 'SectionTellMore.ourStory' });

  return (
    <div className={classNames(css.tellMoreSection, css.sectionPadding)}>
      <div className={css.contentWidth}>
        <div className={css.sectionHead}>
          <div className={css.leftSec}>
            <h2>{heading}</h2>
          </div>
        </div>
        <div className={css.sectionContent}>
          <NamedLink
            className={css.tellmoreBtn}
            name="CMSPage"
            params={{ pageId: 'how_to_donate' }}
          >
            {howToDonate}
          </NamedLink>
          <NamedLink className={css.tellmoreBtn} name="CMSPage" params={{ pageId: 'how_to_buy' }}>
            {howToBuy}
          </NamedLink>
          <NamedLink className={css.tellmoreBtn} name="CMSPage" params={{ pageId: 'how_to_sell' }}>
            {howToSell}
          </NamedLink>
          <NamedLink className={css.tellmoreBtn} name="CMSPage" params={{ pageId: 'about' }}>
            {ourStory}
          </NamedLink>
        </div>
      </div>
    </div>
  );
}

export default SectionTellMore;
