import React from 'react';
import banner from '../../../assets/sustainability-banner.png';

import css from './Sections.module.css';
import classNames from 'classnames';

function SectionSustainability(props) {
  const { intl } = props;
  return (
    <div className={css.contentWidth}>
      <div className={classNames(css.sustainabilitySection, css.sectionPadding)}>
        <img src={banner} alt="banner" />
      </div>
    </div>
  );
}

export default SectionSustainability;
